<template>
    <!-- 醫療影像上傳 -->
    <vs-popup :title="$t('components.upload.title')" style="z-index: 51001;" :active.sync="popupActive" @close="close">
        <vs-row class="cd-appt-input">
            <!-- 選擇民眾 -->
            <vs-col class="px-2 mb-base" vs-lg="6" vs-sm="6" vs-xs="12">
                <div class="flex justify-between items-end">
                    <span><span class="text-danger">* </span>{{ $t('components.upload.patient') }}</span>
                    <span class="cd-p-small">{{ $t('components.upload.patientTip') }}</span>
                </div>
                <el-select class="w-full"
                popper-class="cd-appt-popper"
                v-model="patient.pid"
                id="select"
                ref="select"
                filterable
                remote
                clearable
                placeholder=""
                :remote-method="searchpartnermember"
                :loading="patient.loadingmember"
                @clear="removesearchpartnermember"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                @focus="cancalReadOnly"
                autocomputed>
                    <el-option
                        v-for="(item,index) in patient.MemberList"
                        :key="index"
                        :label="item.name+' ('+item.account+')'"
                        :value="item.pid"
                        clearable
                        filter>
                    </el-option>
                </el-select>
            </vs-col>
            <!-- 選上傳檔案類型 -->
            <vs-col class="px-2 mb-base" vs-w="6" vs-xs="12">
                <span class="text-danger">* </span>
                <span>{{ $t('components.upload.uploadType') }}</span>
                <el-select
                class="w-full"
                popper-class="cd-appt-popper"
                id="select_doc"
                v-model="stype"
                filterable
                clearable
                placeholder="">
                    <el-option
                        v-for="(item, index) in uploadOption"
                        :key="index"
                        :label="item.name"
                        :value="item.type"
                        clearable
                        filter>
                    </el-option>
                </el-select>
            </vs-col>
            <!-- 備註 -->
            <vs-col class="px-2 mb-base">
                <span>{{ $t('components.upload.notes') }}</span>
                <vs-textarea v-model='notes' />
            </vs-col>
            <!-- 上傳的檔案名稱 -->
            <vs-col class="px-2" vs-type="flex" vs-justify="center" vs-align="center">
              <p style="font-weight:bold;">{{ fileName }}</p>
            </vs-col>
            <vs-col class="px-2" vs-type="flex" vs-justify="space-between" vs-align="center">
                <!-- 選擇檔案 -->
                {{ stype!==''?$t('components.upload.accept.'+stype):'' }}
                <label class="cursor-pointer text-primary">
                    <div class="flex items-center">
                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                        <p>{{ $t('components.upload.selectFile') }}</p>
                    </div>
                    <input
                        type="file"
                        name="images"
                        id="images"
                        ref="fileInput"
                        multiple
                        @change="onFileSelected($event)"
                        :accept="file_accept[stype]"
                        hidden>
                </label>
                <!-- 上傳 -->
                <vs-button class="cd-button-2w-icon" icon-pack="feather" icon="icon-upload" @click="uploadImg(0)">
                    {{ $t('components.upload.upload') }}
                </vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>
<script>
import { searchpartnermember, uploadPhoto } from '@/api/user'
export default {
    props: {
        uploadPopupActive: {
            type: Boolean,
            required: true,
        },
        spid: {
            type: Number,
            default: null,
            required: false
        },
        spname: {
            type: String,
            default: null,
            required: false
        },
    },
    data() {
        return {
            patient: {
                pid: null,
                name: null,
                loadingmember: false,
                MemberList: [],
            },
            uploadOption: [
                { type: 0,  name: this.$t('components.upload.type.generalPhoto') },
                { type: 1,  name: this.$t('components.upload.type.generalReport') },
                { type: 2,  name: this.$t('components.upload.type.dicom') },
                { type: 3,  name: this.$t('components.upload.type.funduscopy_R') },
                { type: 4,  name: this.$t('components.upload.type.funduscopy_L') },
                { type: 5,  name: this.$t('components.upload.type.ecg') },
                { type: 6,  name: this.$t('components.upload.type.ear') },
                { type: 7,  name: this.$t('components.upload.type.nasal') },
                { type: 8,  name: this.$t('components.upload.type.throat') },
                { type: 9,  name: this.$t('components.upload.type.oral') },
                { type: 10, name: this.$t('components.upload.type.skin') },
                { type: 11, name: this.$t('components.upload.type.video') },
            ],
            stype: 0,
            notes: null,
            fileName: null,
            uploadfile: [],
            file_accept: [
                'image/*,application/pdf',//患部照片
                'image/*,application/pdf',//檢驗報告
                '.dcm',//DICOM
                'image/*,application/pdf',//眼底鏡圖片左
                'image/*,application/pdf',//眼底鏡圖片右
                'image/*,application/pdf',//心電圖
                'image/*,application/pdf',//五官鏡-耳朵
                'image/*,application/pdf',//五官鏡-鼻子
                'image/*,application/pdf',//五官鏡-喉嚨
                'image/*,application/pdf',//五官鏡-口腔
                'image/*,application/pdf',//五官鏡-皮膚
                'video/*',//影片
            ],
        }
    },
    watch: {
        popupActive(n){
            if(n===true && this.spid && this.spname){
                this.patient =  {
                    pid: null,
                    name: null,
                    loadingmember: false,
                    MemberList: [],
                }
                this.searchpartnermember(this.spname)
                this.patient.pid = this.spid
                this.patient.name = this.spname
            }
        },
        "patient.pid"(n){
            if(!n){
                this.patient.name = ''
                return
            }
            if(!this.patient.name) this.patient.name = _.find(this.patient.MemberList, { 'pid': n }).name
        },
    },
    computed: {
        popupActive: {
            get() {
                return this.uploadPopupActive
            },
            set(v) {
                return v
            },
        },
        partnerid() {
            return this.$store.getters.partner
        },
        uiConfig() {
            var uiConfig = JSON.parse(localStorage.getItem('his_config'))
            uiConfig = uiConfig.emr.dicomData.contents
            uiConfig = _.map(uiConfig);
            uiConfig = _.remove(uiConfig, function(n) {
                return n.show == true;
            });
            return uiConfig
        },
    },
    mounted(){
        // this.$store.dispatch('notify', {title: "text"})
        // console.log("here", this.uiConfig)
    },
    methods: {
        // 關閉popup
        close(){
            this.notes = null
            this.fileName = null
            this.stype = 0
            this.uploadfile = []
            this.patient.pid = ''
            this.$emit("closePopup", false)
        },
        // 搜尋民眾
        searchpartnermember(query) {
            if (query!=''){
                this.patient.loadingmember = true
                setTimeout(() => {
                    searchpartnermember({partnerid: this.partnerid, query: query}).then(res => {
                        this.patient.MemberList = []
                        if(res.data.status=='OK'){
                            this.patient.MemberList= res.data.items
                        }
                        this.patient.loadingmember = false
                    })
                },100)
            }else{
                this.patient.MemberList = []
            }
        },
        // 篩選符合民眾IOS Keyboard無法跳出 修復方法 
        cancalReadOnly() {
            this.$nextTick(() => {
                    const select = document.querySelector('#select')
                    const select_doc = document.querySelector('#select_doc')
                    const timer = setTimeout(() => {
                        select.removeAttribute('readonly')
                        select_doc.removeAttribute('readonly')
                        clearTimeout(timer)
                    },200)
                    const inputElement = this.$el.querySelector('.el-input__inner');
                    if (inputElement) {
                        inputElement.removeAttribute('readonly');
                    }
            })
        },
        // 清空民眾欄位
        removesearchpartnermember() {
            this.patient.MemberList = []
        },
        // 選擇檔案
        onFileSelected(e) {
            this.fileName = e.target.files[0].name
            for (let i = 0; i < e.target.files.length; i++) {
                this.uploadfile.push(e.target.files[i]);
            }
        },
        // 上傳
        uploadImg() {
            if(!this.patient.pid){
                this.$store.dispatch('notify', { color: "danger", title: this.$t('components.upload.feeBack.noPatient') })
                return
            }
            if(this.stype === null){
                this.$store.dispatch('notify', { color: "danger", title: this.$t('components.upload.feeBack.noType') })
                return
            }
            if(!this.uploadfile){
                this.$store.dispatch('notify', { color: "danger", title: this.$t('components.upload.feeBack.noFile') })
                return
            }
            const fd = new FormData()
            fd.append('type', this.stype)
            if(this.stype == 4){
                // 眼底鏡左眼
                fd.append('type', 3)
                fd.append('position', 'l')
            }else if(this.stype == 3){
                fd.append('position', 'r')
            }
            fd.append('spid', this.patient.pid)
            fd.append('note', this.notes!=null?this.notes:'')
            for (let i = 0; i < this.uploadfile.length; i++) {
                fd.append('image[]', this.uploadfile[i])
            }
            uploadPhoto(fd).then(res => {
                if(res.data.status=='OK'){
                    this.close()
                    this.$store.dispatch('notify', { title: this.$t('components.upload.feeBack.upload_success') })
                }
            })
        }
    }
}
</script>